body {
  margin: 0;
  /* font-family: 'arial black'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f2f3;
  /* background-image: url("../src/img/balloon.svg"); */
  /* background-color: rgba(255, 255, 255, 0.5);
  background-color: #d9f2ec; */
  font-family: "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
  transform: rotate(0.03deg);
}

* {
  font-family: 'Fira Sans', sans-serif, 'Kosugi Maru', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
